

<template>
<div>
    <vs-popup :title="$t('ResetPassword')" :active.sync="isPopupActiveLocal">

      <div class="flex items-center justify-center mt-4 mb-4 space-x-2">
          <span class="text-gray-700 text-base" v-if="!showResetPasswordInputs && !showConfirmCodeInput">Tapez votre adresse email pour reçevoir le code de confirmation.</span>
          <span class="text-gray-700 text-base" v-if="showResetPasswordInputs">Saisissez votre nouveau mot de passe.<br> <span class="text-gray-00 text-sm">(doit contenir au minimum 6 caractères)</span> </span>
          <span class="text-gray-700 text-base" v-if="showConfirmCodeInput && !showResetPasswordInputs">Merci! Vous allez recevoir un email.</span>
      </div>

      <div class="flex -mx-3" v-if="!showResetPasswordInputs">
          <div class="w-full px-3">
              <label for="" class="text-xs font-semibold px-1">{{ $t('Email') }}
                  <span class="text-red-500">*</span>
              </label>
              <div class="flex">
                  <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"></div>
                  <input
                      class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                      name="email"
                      type="email"
                      placeholder="votre@email.fr"
                      v-model="email"
                      v-validate="{ email: true }"
                      :disabled="showConfirmCodeInput"
                      :data-vv-as="$t('Email')"
                  >
              </div>
              <span class="text-danger text-sm">{{ errors.first('email') }}</span>
          </div>
      </div>

      <div class="flex mt-3 -mx-3" v-if="showConfirmCodeInput && !showResetPasswordInputs">
          <div class="w-full px-3">
              <label for="" class="text-xs font-semibold px-1">{{ $t('confirmCode') }}
                  <span class="text-red-500">*</span>
              </label>
              <div class="flex">
                  <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"></div>
                  <input
                      class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                      name="confirm_code"
                      type="number"
                      placeholder="12345"
                      v-model="confirmCode"
                      v-validate="{ required: true }"
                      :disabled="showResetPasswordInputs"
                      :data-vv-as="$t('confirmCode')"
                  >
              </div>
              <span class="text-danger text-sm">{{ errors.first('confirm_code') }}</span>
          </div>
      </div>

      <div class="flex mt-3 -mx-3" v-if="showResetPasswordInputs">
          <div class="w-full px-3">
              <label for="" class="text-xs font-semibold px-1">{{ $t('Password') }}
                  <span class="text-red-500">*</span>
              </label>
              <div class="flex">
                  <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"></div>
                  <input
                      class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                      name="password"
                      type="password"
                      placeholder="*******"
                      v-model="password"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('Password')"
                  >
              </div>
              <span class="text-danger text-sm">{{ errors.first('password') }}</span>
          </div>
      </div>

      <div class="flex mt-3 -mx-3" v-if="showResetPasswordInputs">
          <div class="w-full px-3">
              <label for="" class="text-xs font-semibold px-1">{{ $t('Password_confirmation') }}
                  <span class="text-red-500">*</span>
              </label>
              <div class="flex">
                  <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"></div>
                  <input
                      class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                      name="password_confirmation"
                      type="password"
                      placeholder="*******"
                      v-model="passwordConfirmation"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('Password_confirmation')"
                  >
              </div>
              <span class="text-danger text-sm">{{ errors.first('passwordConfirmation') }}</span>
          </div>
      </div>

      <vs-button
          v-if="!showConfirmCodeInput"
          class="w-full flex justify-center rounded-full mt-4 p-3 tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
          ref="loadableButton"
          @click="resetPassword"
          :disabled="!validateForm()"
      >
        {{ $t('ResetPassword') }}
      
        <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
        </svg>
      </vs-button>

      <vs-button
           v-if="showConfirmCodeInput && !showResetPasswordInputs"
          class="w-full flex justify-center rounded-full mt-4 p-3 tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
          ref="loadableButton"
          @click="sendConfirmCode"
          :disabled="!validateFormConfirmCode()"
      >
        {{ $t('Confirm') }}
      
        <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
        </svg>
      </vs-button>

      <vs-button
          v-if="showResetPasswordInputs"
          class="w-full flex justify-center rounded-full mt-4 p-3 tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
          ref="loadableButton"
          @click="sendResetPassword"
          :disabled="!validateFormSendPassword()"
      >

        {{ $t('ResetPassword') }}
      
        <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
        </svg>
      </vs-button>
    </vs-popup>
</div>
</template>

<script>

export default {
  props: {
    isPopupActive: {
      required: true
    },
  },
  data () {
    return {
      popupBackgroundColor:"#f8f8f8",
      showResetPasswordInputs: false,
      showConfirmCodeInput: false,
      confirmCode: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      reg: RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    }
  },
  watch: {
    isPopupActiveLocal (newValue, oldValue) {
      this.email = ''
      this.password = ''
      this.confirmCode = ''
      this.passwordConfirmation = ''
      this.showConfirmCodeInput = false
      this.showResetPasswordInputs = false
    }
  },
  components: {
  },
  computed: {
    isPopupActiveLocal: {
      get () {
        return this.isPopupActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }

    },
  },
  methods: {
    validateForm () {
      return (this.email == "") ? false : (this.reg.test(this.email)) ? true : false;
    },
    validateFormConfirmCode () {
      return (this.email == "" || this.confirmCode == "") ? false : (this.reg.test(this.email)) ? true : false;
    },
    validateFormSendPassword () {
      return (this.password == "" || this.passwordConfirmation == "" || this.password.length < 8 || this.password !== this.passwordConfirmation)? false : true;
    },
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Tentative de connexion',
          text: 'Vous êtes déjà connecté!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },

    async resetPassword () {
      // If form is not validated or user is already login return
      if (!this.validateForm() || !this.checkLogin()) return

      this.$vs.loading()

      const payload = {
        data: {
          email: this.email,
        },
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      
      let response = await this.$store.dispatch('auth/resetPassword', payload)

      if(response.status == 200) {
        this.showConfirmCodeInput = true
        // this.$emit('closeSidebar')
      }
      
    },

    async sendConfirmCode () {
      // If form is not validated or user is already login return
      if (!this.validateFormConfirmCode() || !this.checkLogin()) return

      this.$vs.loading()

      const payload = {
        data: {
          email: this.email,
          code: Number(this.confirmCode),
        },
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      
      let response = await this.$store.dispatch('auth/sendConfirmCode', payload)

      if(response.status == 200) {
        this.showResetPasswordInputs = true
        // this.$emit('closeSidebar')
      }
      
    },

    async sendResetPassword () {
      // If form is not validated or user is already login return
      if (!this.validateFormSendPassword() || !this.checkLogin()) return

      this.$vs.loading()

      const payload = {
        data: {
          email: this.email,
          code: Number(this.confirmCode),
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        },
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      
      let response = await this.$store.dispatch('auth/sendResetPassword', payload)

      if(response.status == 200) {
        this.$emit('closeSidebar')
      }
      
    },
    
  },
	
}

</script>

<style lang="css" scoped>

</style>

    